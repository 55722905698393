<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
      </div>
      <b-avatar
        size="30"
        variant="light-primary"
      />
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder">
          {{ userData.prenom }}  {{ userData.nom }}
        </p>
      </div>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="getTeamviewer"
    >
      <feather-icon
        size="16"
        icon="DownloadCloudIcon"
        class="mr-50"
      />
      <span>{{ $t('USER_DROPDOWN.DOWNLOAD_TEAMVIEWER') }}</span>
    </b-dropdown-item>

    <!--
      <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="updatePassword"
    >
      <feather-icon
        size="16"
        icon="LockIcon"
        class="mr-50"
      />
      <span>{{ $t('USER_DROPDOWN.UPDATE_PASSWORD') }}</span>
    </b-dropdown-item>
    -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="goToProfilPage"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('USER_DROPDOWN.PROFIL') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="downloadDocUtilisateur"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>{{ $t('USER_DROPDOWN.HELP') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('USER_DROPDOWN.LOGOUT') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

// eslint-disable-next-line import/extensions
const config = require('../../../../config.js')

export default {
  components: {
    BAvatar,
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('jwt/logout').then(() => {
        this.$router.push({ name: 'auth-login' })
      })
    },
    getTeamviewer() {
      window.open(config.urlTeamViewer, '_blank')
    },

    updatePassword() {
      const that = this
      this.$swal({
        title: this.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.TITLE'),
        input: 'password',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.CONFIRM_BUTTON'),
        cancelButtonText: this.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.CANCEL_BUTTON'),
        showLoaderOnConfirm: true,
        preConfirm(password) {
          if (!password) return null
          return password
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            that.$store.dispatch('utilisateur/updatePassword', { password: result.value })
              .then(response => {
                if (response.status === 201) {
                  that.$swal({
                    title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.SUCCESS.TITLE'),
                    text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.SUCCESS.TEXT'),
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  that.$swal({
                    title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TITLE'),
                    text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TEXT'),
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              .catch(() => {
                that.$swal({
                  title: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TITLE'),
                  text: that.$i18n.t('USER_DROPDOWN.MODAL_PASSWORD.ERROR.TEXT'),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
    },
    goToProfilPage() {
      this.$router.push({ name: 'profil' })
    },
    downloadDocUtilisateur() {
      this.$store.dispatch('application/docUtilisateur')
        .then(response => {
          if (response.status === 200) {
            const linkSource = `data:${response.data.contentType};base64,${response.data.base64}`
            const downloadLink = document.createElement('a')
            const fileName = response.data.name

            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
          } else {
            this.$swal({
              title: this.$i18n.t('USER_DROPDOWN.MODAL_DOWNLOADDOC.FILE_NOT_FOUND.TITLE'),
              text: this.$i18n.t('USER_DROPDOWN.MODAL_DOWNLOADDOC.FILE_NOT_FOUND.TEXT'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch(() => {
          this.$swal({
            title: this.$i18n.t('USER_DROPDOWN.MODAL_DOWNLOADDOC.ERROR.TITLE'),
            text: this.$i18n.t('USER_DROPDOWN.MODAL_DOWNLOADDOC.ERROR.TEXT'),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style >
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
    margin-left: 0.8rem;
}

</style>
