<template>
  <b-img
    v-if="skin === 'light'"
    :src="getLogo()"
    alt="Logo"
  />
  <b-img
    v-else
    :src="getLogo()"
    alt="Logo"
  />
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BImg,
  },
  computed: {
    skin() {
      const { skin } = useAppConfig()
      return skin.value
    },
  },
  methods: {
    getLogo() {
      // const { host } = window.location
      let codename = 'absystech'

      if (localStorage.codename) codename = localStorage.codename

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // eslint-disable-next-line import/no-dynamic-require
      const logo = require(`@/assets/logos/${this.skin}/logo-${codename}.png`)
      return logo
    },
  },
}

</script>
