<template>
  <b-nav v-if="soldeActiveSociete != null">
    <b-nav-item
      v-b-tooltip.hover.top="$t('NOTIFICATION.PRIS_EN_CHARGE')"
      @click="redirect('free')"
    >
      <feather-icon
        :badge="soldeActiveSociete.non_pris_en_charge?soldeActiveSociete.non_pris_en_charge:'0'"
        badge-classes="bg-danger"
        class="text-danger"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>

    <b-nav-item
      v-b-tooltip.hover.top="$t('NOTIFICATION.EN_COURS')"
      @click="redirect('fixing')"
    >
      <feather-icon
        :badge="soldeActiveSociete.en_cours?soldeActiveSociete.en_cours:'0'"
        badge-classes="bg-success"
        class="text-success"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>

    <b-nav-item
      v-b-tooltip.hover.top="$t('NOTIFICATION.EN_ATTENTE')"
      @click="redirect('wait')"
    >
      <feather-icon
        :badge="soldeActiveSociete.en_attente?soldeActiveSociete.en_attente:'0'"
        badge-classes="bg-warning"
        class="text-warning"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>
  </b-nav>
</template>

<script>
import {
  BNav, BNavItem, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNav,
    BNavItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    soldeActiveSociete() {
      return this.$store.getters['hotline/getNotificationsSociete']
    },
  },
  methods: {
    /**
     * Méthode qui permet de rediriger vers la page "Mes demandes" avec le statut voulu
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    redirect(etat) {
      if (this.$route.name !== 'requests-personal') {
        this.$router.push({ name: 'requests-personal' })
      }
      this.$store.dispatch('hotline/setRequestStatusSelected', { status: etat })
    },
  },
}
</script>

<style>
  b-nav-item {
    margin-right: 15px;
  }
</style>
