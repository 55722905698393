<template>
  <b-row>
    <b-col md="6">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT  © {{ new Date().getFullYear() }}
          <b-link
            class="ml-25"
            href="https://www.absystech.fr/"
            target="_blank"
          >AbsysTech</b-link>
          <span class="d-none d-sm-inline-block">, All rights Reserved</span>
        </span>
      </p>
    </b-col>

    <b-col
      md="6"
      class="text-right"
    >
      <b-link
        v-b-modal.modal-ML
        class="ml-25"
      >
        Mentions légales
      </b-link>

      <b-modal
        id="modal-ML"
        title="Mentions Légales"
        size="lg"
        hide-footer
      >
        <b-overlay
          :show="loading"
          rounded="lg"
        >
          <b-card-text v-html="mentionsLegales" />
        </b-overlay>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import { BLink, BRow, BCol, BModal, BCardText, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BModal,
    BCardText,
    BOverlay,
  },
  data() {
    return {
      mentionsLegales: null,
      loading: true,
    }
  },
  mounted() {
    this.$store.dispatch('application/mentionsLegales').then(res => {
      this.loading = false
      this.mentionsLegales = res.data.mentionsLegales
    })
  },
}
</script>
