<template>
  <b-badge
    v-if="soldeActiveSociete !== null"
    :variant="variantStatut()"
  >
    Solde : {{ soldeActiveSociete }} crédit(s)
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  data() {
    return {
      color: 'info',
    }
  },
  computed: {
    soldeActiveSociete() {
      return this.$store.getters['societe/getSoldeActiveSociete']
    },
  },
  methods: {
    variantStatut() {
      let color = 'info'

      if (this.soldeActiveSociete < 0) {
        color = 'danger'
      } else if (this.soldeActiveSociete < 10) {
        color = 'primary'
      } else {
        color = 'success'
      }

      return `${color}`
    },
  },
}
</script>

<style>

</style>
