<template>
  <div
    v-if="societes.length > 0"
    class="ml-50"
  >
    <b-nav-item-dropdown
      v-if="getSocietes().length > 0"
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template #button-content>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
        >
          <span class="align-middle">{{ societeActive.nom_commercial || societeActive.societe }}</span>

          <feather-icon
            icon="ChevronDownIcon"
            class="ml-50"
          />
        </b-button>
      </template>
      <b-dropdown-item
        v-for="societeObj in getSocietes()"
        :key="societeObj.id_societe"
        @click="setDefaultSociete(societeObj)"
      >
        <span class="ml-50">{{ societeObj.nom_commercial || societeObj.societe }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-button
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="flat-primary"
    >
      <span class="align-middle">{{ societeActive.nom_commercial || societeActive.societe }}</span>

    </b-button>
  </div>

</template>

<script>
import { BNavItemDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      societes: [],
      societeActive: {
        idSociete: null,
        ref: null,
        societe: null,
        nom_commercial: null,
        idContact: null,
      },
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.userData)
    this.societes = userData.societes
    if (localStorage.activeSociete) this.societeActive = JSON.parse(localStorage.activeSociete)
  },
  methods: {
    setDefaultSociete(societe) {
      this.societeActive = societe
      this.$store.dispatch('societe/updateActiveSociete', societe)
      localStorage.setItem('activeSociete', JSON.stringify(societe))
      this.$store.dispatch('societe/getSolde', { idSociete: societe.idSociete })
      this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: societe.idSociete })
    },
    getSocietes() {
      return this.societes.filter(societe => societe.idSociete !== this.societeActive.idSociete)
    },
  },
}
</script>

<style>

</style>
