const user = JSON.parse(localStorage.getItem('userData'))

const defaultNaviguation = [
  {
    title: 'MENU.NOUVELLE_DEMANDE',
    route: 'new-request',
    icon: 'PlusCircleIcon',
  },
]

const requireDroit = [
  {
    title: 'MENU.MES_DEMANDES',
    route: 'requests-personal',
    icon: 'UserIcon',
    requireDroit: 'mes_demandes',
  },
  {
    title: 'MENU.TOUTE_LES_DEMANDES',
    route: 'requests-entity',
    icon: 'UsersIcon',
    requireDroit: 'toutes_les_demandes',
  },
  {
    title: 'MENU.SOLDE',
    route: 'balance',
    icon: 'ActivityIcon',
    requireDroit: 'solde',
  },
  {
    title: 'MENU.FACTURES',
    route: 'factures',
    icon: 'FileTextIcon',
    requireDroit: 'facture',
  },
]

const dynamicNaviguation = []

requireDroit.forEach(e => {
  if (user !== null) {
    const haveDroit = user.droits.filter(el => el.droit === e.requireDroit && el.can === true)
    if (haveDroit.length > 0) {
      dynamicNaviguation.push(e)
    }
  }

})

const naviguation = defaultNaviguation.concat(dynamicNaviguation)

export default naviguation

